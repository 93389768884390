import React from "react"
import { LinkBox, LinkOverlay, Box, Heading, Text, Image } from "@chakra-ui/react"
import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"

const CollectionCard = ({ collection }) => {
  const { urlResolver } = useRoutes()

  const item = {
    ...collection,
    link: urlResolver(collection),
    image: {
      alt: collection?.title || "",
      src: collection?.image || "",
    },
    content: collection?.descriptionHtml,
  }

  return (
    <LinkBox as="article" maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
      {item?.image?.src && <Image width="100%" height="60%" objectFit="cover" src={item?.image?.src} alt={item?.image?.alt} />}

      <Box p="4" align="left">
        <Heading as="h3" fontSize="xl" fontWeight="semibold" lineHeight="tight" isTruncated>
          <LinkOverlay as={Link} to={item?.link.url} title={item?.link.title}>
            {item?.title}
          </LinkOverlay>
        </Heading>

        <Text fontSize="sm">{item?.content}</Text>
      </Box>
    </LinkBox>
  )
}

export default React.memo(CollectionCard)
