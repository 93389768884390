import React from "react"
import { graphql, PageProps } from "gatsby"
import Page from "@app/components/Collection/CollectionList"

export type Props = PageProps<GatsbyTypes.PageCollectionsQuery, GatsbyTypes.PageCollectionsQueryVariables>

export const query = graphql`
  query PageCollections {
    page: sanityPageCollections {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
    collections: allSanityCollection(filter: { shopify: { published: { eq: true } } }, sort: { fields: _updatedAt }) {
      edges {
        node {
          ...SanityCollectionFragment
        }
      }
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
