import React from "react"
import { Container, SimpleGrid, Box } from "@chakra-ui/react"
import CollectionCard from "./CollectionCard"
import { useCore } from "@app/hooks/useCore"
import type { Props } from "@app/pages/collections"
import type { PageProps } from "@root/types/global"
import Title from "@app/components/Sections/Title/Title"

const CollectionList: React.FC<PageProps<Props>> = ({ page, collections }) => {
  const {
    helpers: { sanityContent },
  } = useCore()

  const item = {
    ...page,
    image: {
      alt: page?.image?.alt || "",
      src: page?.image?.asset?.url || "",
    },
    content: sanityContent(page?.content),
  }

  const items = collections?.edges?.map(({ node }) => node)

  return (
    <>
      <Title title={item?.title} />
      <Container maxW="container.xxl" px={[4, 16]} pb={16}>
        <Box as="section" pb={8} textAlign="center">
          <Box mb={8}>{item?.content}</Box>

          <SimpleGrid columns={[1, 3]} spacing={10}>
            {items && items?.map(collection => <CollectionCard key={collection.title.toString()} collection={collection} />)}
          </SimpleGrid>
        </Box>
      </Container>
    </>
  )
}

export default React.memo(CollectionList)
